<div class="flex w-full">
  <div class="flex flex-col pr-2">
    @for (item of customPresets; track item.label) {
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [materialStyle]="(item.label | getSelected: dates) ? 'flat' : 'basic'"
        [style]="(item.label | getSelected: dates) ? 'primary' : undefined"
        (clickEvent)="selectRange(item, $event)"
        ><span class="whitespace-nowrap">
          {{ DateQueryLabel[item.label] }}
        </span>
      </itq-mat-button>
    }
  </div>
  @if (dates?.label === DateQueryType.Custom) {
    <div
      class="flex-grow w-[325px] border-l border-border"
      (click)="$event.stopPropagation()"
    >
      <mat-calendar
        [selected]="dateRange"
        (selectedChange)="rangeChanged($event)"
      ></mat-calendar>
      <div class="flex justify-end">
        <itq-mat-button
          class="mr-2"
          [type]="'button'"
          [materialStyle]="'basic'"
          [align]="Align.START"
          (clickEvent)="onCancel()"
          >CANCEL
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [disabled]="!(dateRange.start?.isValid() && dateRange.end?.isValid())"
          [materialStyle]="'flat'"
          [align]="Align.START"
          [style]="'primary'"
          (clickEvent)="onApply()"
        >
          <pre>APPLY</pre>
        </itq-mat-button>
      </div>
    </div>
  }
</div>
