import { Component, Input, OnInit } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'itq-panel-info',
  templateUrl: './panel-info.component.html',
  styleUrls: ['./panel-info.component.scss'],
})
export class PanelInfoComponent implements OnInit {
  @Input() label = '';
  @Input() description = '';
  @Input() message = '';
  @Input() icon = 'exclamation-circle';

  constructor() {}

  ngOnInit(): void {}
}
